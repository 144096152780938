import React from 'react';

import Banner from 'sats-ui-lib/react/banner';
import Expander from 'sats-ui-lib/react/expander';
import Text from 'sats-ui-lib/react/text';

import ContentContainer from 'components/content-container/content-container';
import Debt from 'components/debt/debt';
import MyPageLayout from 'components/my-page-layout/my-page-layout';
import PaymentReceiptGroup from 'components/payment-receipt-group/payment-receipt-group';

import type { MyPagePayments as Props } from './my-page-payments.types';

const MyPagePayments: React.FunctionComponent<Props> = ({
  cashCollection,
  debt,
  layout,
  payments,
}) => (
  <MyPageLayout {...layout}>
    <ContentContainer>
      <div className="my-page-payments">
        {cashCollection ? <Banner {...cashCollection} /> : null}
        {debt ? (
          <div className="my-page-payments__section">
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
            >
              {debt.title}
            </Text>
            <Text elementName="p">{debt.help}</Text>
            {debt.entries.map(entry => (
              <div key={entry.id}>
                <Debt {...entry} />
              </div>
            ))}
          </div>
        ) : null}
        {payments ? (
          <div className="my-page-payments__section">
            <Text
              elementName="h2"
              size={Text.sizes.headline3}
              theme={Text.themes.emphasis}
            >
              {payments.title}
            </Text>
            <div>
              {payments.entries.map(entry => (
                <Expander
                  {...entry.props}
                  itemRenderer={PaymentReceiptGroup}
                  key={entry.key}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </ContentContainer>
  </MyPageLayout>
);

export default MyPagePayments;
